import React, {Component} from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { Container } from '../components/Theme'

const NewsItemContainer = styled(Container)`
  max-width: 800px;
`;

const BodyText = styled.div`
padding-top: 2rem;
`

export default class NewsLayout extends Component {

  render(){

    // Pull data from graphql
    const {markdownRemark: post} = this.props.data;
    

    return(
      <Layout>
        <Helmet
          title={`${post.frontmatter.title} - Metro Communications News`}
          meta={[
            { name: 'description', content: post.excerpt },
            { name: 'keywords', content: `` },
          ]}
        />
        <NewsItemContainer>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          {post.frontmatter.image && <Img fluid={post.frontmatter.image.childImageSharp.fluid} />}
          <BodyText dangerouslySetInnerHTML={{ __html: post.html }}></BodyText>
        </NewsItemContainer>
      </Layout>
    )
  }
}

export const newsTemplateQuery = graphql`
  query newsTemplateQuery($id: String!) {
    markdownRemark(id: {eq: $id}){
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`